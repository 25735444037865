<template>
  <v-container container--fluid>
    <!-- <v-row class="mt-12 mb-4" justify="center">
      <v-col cols="10" md="10">
        <h2 class="mt-4 headline">환영합니다.</h2>
      </v-col>
    </v-row> -->
    <v-row justify="center">
      <v-col cols="10" md="5">
        <v-col cols="10" md="10" class="mt-8 mb-4">
          <h2 class="mt-4 headline" style="font-weight: 700; font-size: 24px; line-height: 30px">
            사장님, 1분만에 가입하고 찾아오는 손님을 늘리세요.
          </h2>
        </v-col>

        <v-row class="mb-8">
          <v-col cols="12" class="mt-5 mb-8">
            <form @submit.prevent="onSubmit" id="signUpForm">
              <v-col>
                <v-col v-if="!userIsAuthenticated">
                  <v-col>
                    <v-col>
                      <div class="label">이메일 주소*</div>
                    </v-col>
                    <v-col class="mt-1">
                      <v-text-field
                        v-model="form.email"
                        name="email"
                        type="email"
                        v-validate="'required|email'"
                        :rules="[errors.first('email') || !errors.has('email')]"
                        single-line
                        outlined
                        solo
                        flat
                        required></v-text-field>
                    </v-col>
                  </v-col>
                </v-col>
                <v-col v-if="!userIsAuthenticated">
                  <v-col>
                    <v-col>
                      <div class="label">비밀번호*</div>
                    </v-col>
                    <v-col class="mt-1">
                      <v-text-field
                        v-model="form.password1"
                        name="password"
                        :type="showPassword ? 'text' : 'password'"
                        :append-icon="showPassword ? 'visibility' : 'visibility_off'"
                        ref="password"
                        placeholder="영어대소문자, 숫자로 8자 이상"
                        v-validate="'required|min:8'"
                        :rules="[errors.first('password') || !errors.has('password')]"
                        @click:append="showPassword = !showPassword"
                        single-line
                        outlined
                        solo
                        flat
                        required>
                      </v-text-field>
                    </v-col>
                  </v-col>
                </v-col>

                <v-col>
                  <v-col>
                    <v-col>
                      <div class="label">업체명*</div>
                    </v-col>
                    <v-col class="mt-1">
                      <v-text-field
                        v-model="form.name"
                        name="name"
                        type="text"
                        v-validate="'required'"
                        :rules="[errors.first('name') || !errors.has('name')]"
                        single-line
                        outlined
                        solo
                        flat
                        required></v-text-field>
                    </v-col>
                  </v-col>
                </v-col>
                <v-col>
                  <v-col>
                    <v-col>
                      <div class="label">휴대전화 번호*</div>
                    </v-col>
                    <v-col class="mt-1">
                      <v-text-field
                        v-model="form.contacts"
                        name="contacts"
                        type="text"
                        v-validate="'required|min:10|phoneNumber'"
                        :rules="[errors.first('contacts') || !errors.has('contacts')]"
                        single-line
                        outlined
                        solo
                        flat
                        required></v-text-field>
                    </v-col>
                  </v-col>
                </v-col>
                <v-col>
                  <label>
                    <input type="checkbox" required />
                    {{ isMatching() ? '슈퍼차트' : '슈퍼멤버스' }}
                    <router-link target="_blank" to="/terms/" class="red--text text--lighten-1">이용약관</router-link>
                    및
                    <router-link target="_blank" to="/terms/" class="red--text text--lighten-1"
                      >개인정보취급방침</router-link
                    >
                    에 동의합니다.
                  </label>
                  <br />
                  <label>
                    <input v-model="form.agree_to_mail" type="checkbox" />
                    주요 공지사항 및 마케팅 수신에 동의합니다.
                  </label>
                </v-col>
                <v-col class="mt-4 mb-12 text-center">
                  <v-btn depressed color="primary" type="submit">가입하기</v-btn>
                </v-col>
              </v-col>
            </form>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import userMixin from '@/components/shared/userMixin';

export default {
  name: 'Signup',
  mixins: [userMixin],
  data() {
    return {
      form: {
        password1: '',
        password2: '',
        name: '',
        charger: '사장님',
        contacts: '',
        email: '',
        route: 'BI',
        recommended: 'TALLY',
        agree_to_mail: '',
      },
      option: { root: {} },
      showPassword: false,
    };
  },
  methods: {
    moveBlogSite() {
      location.href = 'https://rank.supermembers.co.kr/';
    },
    onSubmit() {
      this.$validator
        .validateAll()
        .then(success => {
          if (!success) {
            console.log(success);
            return;
          }
          if (this.userIsAuthenticated) {
            this.$store.dispatch('logout');
            return null;
            // return this.$store.dispatch('signCompanyUp', {...this.form, email: this.user.email})
          } else {
            return this.$store.dispatch('logout').then(() => {
              return this.$store.dispatch('signUserUp', this.form).then(() => {
                // return this.$store.dispatch('signCompanyUp', {...this.form, step: 0})
              });
            });
          }
        })
        .then(() => {
          if (this.errors.any()) {
            this.$store.commit('setMsg', this.errors.all()[0]);
            this.$store.commit('setShowSnackbar', true);
          } else if (this.$store.getters.error) {
            this.$store.commit('setMsg', this.$store.getters.error.message || this.$store.getters.error);
            this.$store.commit('setShowSnackbar', true);
          } else {
            this.axios
              .put('/company/user', {
                id: this.user.id,
                last_login: new Date(),
              })
              .then(() => {
                this.$router.push(`/cardform/${this.user.id}`);
              });
          }
        });
    },
    isMatching() {
      return process.env.VUE_APP_TARGET === 'campaign' || location.href.indexOf('superchart') > -1;
    },
  },
  created() {
    if (this.userIsAuthenticated) {
      if (this.isMatching()) {
        this.$router.push('/campaign/search');
      } else {
        this.$router.push('/company/report');
      }
    } else {
      this.axios
        .get('/company/option')
        .then(option => {
          delete option.data.root['A'];
          delete option.data.root['B'];
          this.option = option.data;
          if (this.isMatching()) {
            this.option.root['None'] = '슈퍼차트를 어떻게 아시게 되셨습니까?';
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
  },
};
</script>

<style scoped lang="scss">
@import '../../assets/scss/form.scss';
@import '../../assets/scss/custom';

.line {
  border-bottom: 1px solid #484848;
}

::v-deep .v-btn {
  margin-left: 0;
  margin-right: 0;
}

.label {
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.92;
  letter-spacing: normal;
  color: #484848;
}

.blog-button {
  color: white;
  margin: 40px 0px;
}

@media (max-width: 724px) {
  .blog-button {
    margin: 24px 0px;
  }
}
</style>
